import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe2.css'
import certis from "../images/certis.png" // Tell webpack this JS file uses this image

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image
import ingredient from "../images/ingredient.png" // Tell webpack this JS file uses this image
import receita1 from "../images/receita1.jpg" // Tell webpack this JS file uses this image
import receita2 from "../images/receita2.jpg" // Tell webpack this JS file uses this image
import receita3 from "../images/receita3.jpg" // Tell webpack this JS file uses this image
import Layout from "../components/layout"
import SEO from "../components/seo"
import StopMotionSalmaoCremoso from "../images/StopMotionSalmaoCremoso.mp4"
import angus from "../images/angus-icon.png" // Tell webpack this JS file uses this image
import price1 from "../images/price-1.png" // Tell webpack this JS file uses this image
import price2 from "../images/price-2.png" // Tell webpack this JS file uses this image
import price3 from "../images/price-3.png" // Tell webpack this JS file uses this image
import tempo20 from "../images/tempo-20.png" // Tell webpack this JS file uses this image
import tempo25 from "../images/tempo-25.png" // Tell webpack this JS file uses this image
import tempo30 from "../images/tempo-30.png" // Tell webpack this JS file uses this image
import time from "../images/time.png" // Tell webpack this JS file uses this image

import tempo40 from "../images/tempo-40.png" // Tell webpack this JS file uses this image
import pessoas from "../images/pessoas.png" // Tell webpack this JS file uses this image
import dificult from "../images/diff-1.png" // Tell webpack this JS file uses this image
import dificult2 from "../images/diff-2.png" // Tell webpack this JS file uses this image
import dificult3 from "../images/diff-3.png" // Tell webpack this JS file uses this image
import dificult4 from "../images/diff-4.png" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaA = () => (
  <Layout>
    <SEO title="El Gaúcho Martin - Receita 1" />

    <div id="rec2-section-1">
        <div className="foto-bg">

        </div>
        <div className="wrapper">
        <div className="titulo absara">
            Bife à Portuguesa
        </div>
        <div className="subtitulo absara">
            Receita Portuguesa
        </div>
        <div className="desc sneak-regular">
        Com origem na América do Sul, a carne tenra e saborosa, conquista a gastronomia portuguesa e seduz-nos através de uma experiência típica e tradicional.
        </div>
        </div>
    </div>
    <div id="rec2-section-2">
    <div className="caracts">
            <div className="wrapper">
                <div className="card-caracts">
                    <div className="col-1">
                        <div className="titulo absara">
                            2 Pessoas
                        </div>
                        <div className="imagem">
                        <img src={pessoas} alt="ingredient" />

                        </div>
                    </div>
                    <div className="col-1">
                    <div className="titulo absara">
                            Dificuldade
                        </div>
                        <div className="imagem">
                        <img src={dificult3} alt="ingredient" />

                        </div>
                        </div>
                        <div className="col-1">
                        <div className="titulo absara">
                            30 minutos
                        </div>
                        <div className="imagem">
                        <img src={time} alt="ingredient" />

                        </div>
                        </div>
                        <div className="col-1">
                        <div className="titulo absara">
                           Custo
                        </div>
                        <div className="imagem">
                        <img src={price1} alt="ingredient" />

                        </div>
                        </div>
                </div>
            </div>
        </div>
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        <div className="icone">
                        <img src={ingredient} alt="ingredient" />
                        </div>
                        <div className="titulo">
                       Ingredientes
                        </div>
                        
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            300g 
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de Bife
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 
                        </div>
                        <div className="text sneak-regular">  
                         Folhas de louro
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 
                        </div>
                        <div className="text sneak-regular">  
                         Dentes de alho
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            40g 
                        </div>
                        <div className="text sneak-regular">  
                         Manteiga
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            400g
                        </div>
                        <div className="text sneak-regular">  
                         Batata
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B
                        </div>
                        <div className="text sneak-regular">  
                        Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B  
                        </div>
                        <div className="text sneak-regular">  
                         Óleo
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                         Pimenta
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                           5cl 
                        </div>
                        <div className="text sneak-regular">  
                        Azeite
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            20cl 
                        </div>
                        <div className="text sneak-regular">  
                        Vinho branco
                        </div>
                        
                    </div>
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        <div className="icone">
                        <img src={recipeicon} alt="ingredient" />
                        </div>
                        <div className="titulo">
                       Receita
                        </div>
                        
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                        Retire os bifes da embalagem, 20 minutos antes da confeção.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 
                        </div>
                        <div className="text sneak-regular">  
                         Numa frigideira bem quente, adicione os bifes previamente temperados com sal e pimenta e frite 2 a 3 minutos de cada lado.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3
                        </div>
                        <div className="text sneak-regular">  
                         Retire os bifes e reserve.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 
                        </div>
                        <div className="text sneak-regular">  
                         Na frigideira onde se fritou os bifes adicione o vinho branco, os alhos esmagados e as folhas de louro. Deixe reduzir o vinho a metade e baixe o lume.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 
                        </div>
                        <div className="text sneak-regular">  
                         Deixe derreter, mexendo o molho até ficar homogéneo.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            6  
                        </div>
                        <div className="text sneak-regular">  
                         Sirva com as batatas cortadas às rodelas previamente fritas.
                        </div>
                        
                    </div>
   
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            612,1
                        </div>
                        <div className="titulo sneak-regular">
                            34,4
                        </div>
                        <div className="titulo sneak-regular">
                            16,1
                        </div>
                        <div className="titulo sneak-regular">
                          39,1
                        </div>
                        <div className="titulo sneak-regular">
                          0,7
                        </div>
                        <div className="titulo sneak-regular">
                          36,5
                        </div>
                        <div className="titulo sneak-regular">
                          3,3
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
    <div id="rec2-section-6">
      <div className="wrapper">
         <div className="col-1">
         
                            <img  src={certis} alt="" />
                        
         </div>
         <div className="col-2">
            <div className="card">
               <div className="contentor">
               <p className="sneak-regular opacity">O nosso selo EU 481, baseia-se no regulamento CE 418/2014.<br></br>
Este regulamento comunitário define as regras para importação de Carne de Bovino de Alta Qualidade de Países Terceiros.<br></br>
As carnes são avaliadas por um classificador que é agente das autoridades locais e segue, na avaliação e classificação das carcaças, um método aprovado pelas autoridades.
A carne importada ao abrigo do 481, é obtida a partir de animais com idade inferior a 30 meses que por mais de 100 dias, foram alimentados exclusivamente com rações de alta qualidade e muito alto conteúdo energético. 
Vulgarmente esta carne é chamada de Grain-Fed. Diferencia-se por maciez extraordinária e uma maior suculência resultante da infiltração de gordura intramuscular, mais conhecido por marmoreio.</p>
</div>
            </div>
         </div>
      </div>
   </div>
    {/* <div id="rec-section-3">
        <div className="wrapper">
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={StopMotionSalmaoCremoso} type="video/mp4" />
         </video>
            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={angus} alt="Logo" /><br></br>
                        A carne oriunda da América do Sul merece uma confeção cuidadosa que garanta toda a suculência e assegurem maciez, sem colocar de parte o sabor irresistível.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div> */}


  </Layout>
)


export default ReceitaA
